import { Component } from '@angular/core';
import { BaseActionButton } from '../shared/base-button';

@Component({
  selector: 'intello-buttons-default',
  templateUrl: './buttons-default.component.html',
  styleUrls: ['./buttons-default.component.scss']
})
export class ButtonsDefaultComponent extends BaseActionButton {
}
