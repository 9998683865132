<button [disabled]="isLoading || disabled"
  [ngClass]="[colorClass, sizeClass, isMenuOpened ? 'mat-menu-trigger--opened' : '' ,'group flex flex-row items-center']"
  [matMenuTriggerFor]="menu" (menuOpened)="isMenuOpened = true" (menuClosed)="isMenuOpened = false">
  <ng-container [ngTemplateOutlet]="label?.template"></ng-container>
</button>
<mat-menu #menu="matMenu" style="height: 40px; padding: 0;" matRipple yPosition="below" xPosition="after">
  <button mat-menu-item *ngFor="let action of actions;" (click)="emitAction($event, action)" [class]="action.colorClass"
    [disabled]="action.isDisabled" [ngClass]="[action.colorClass, action.sizeClass, 'group flex flex-row items-center justify-between', 
    action.order === 'icon-last' ? 'flex-row-reverse': '', 
  IsNullOrUndefinedOrEmpty(action.displayText) ? '' : 
      IsNullOrUndefinedOrEmpty(action.icon) ? 'button-icon-padding--all-around' : 
      action.order === 'icon-first' ? 'button-icon-padding--end' : 'button-icon-padding--start']">
    <ng-container [ngTemplateOutlet]="action.content">
    </ng-container>
  </button>
</mat-menu>