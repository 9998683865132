<button [attr.data-testid]="testId" [matTooltipDisabled]="tooltipDisabled" [matTooltip]="tooltipText" [disabled]="isDisabled || isLoading"
  [class.disabled]="isDisabled || isLoading" matRipple [matRippleDisabled]="isDisabled || isLoading"
  (click)="onClick.emit()"
  class="hover:opacity-80 h-10 group flex flex-row items-center leading-normal rounded-md   text-sm font-medium px-4 py-2 group break-normal"
  [ngClass]="colorClass === 'default' ? 'bg-primary-blue text-white' : colorClass">

  <!-- LOADER REVERSE -->
  <ng-container *ngIf="isLoading && order === 'icon-last'">
    <mat-spinner [diameter]="20"></mat-spinner>
  </ng-container>

  <!-- SUCCESSFUL -->
  <ng-container *ngIf="isSuccessful && !isLoading && order === 'icon-last'">
    <mat-icon [ngClass]="[sizeClass, 'large']" [svgIcon]="'check'"></mat-icon>
  </ng-container>

  <!-- ICON -->
  <ng-container *ngIf="!IsNullOrUndefinedOrEmpty(icon)">
    <mat-icon [ngClass]="[sizeClass, iconClass]" [svgIcon]="icon"></mat-icon>
  </ng-container>

  <!-- CONTENT -->
  {{ displayText }}

  <div>
    <ng-content></ng-content>
  </div>

  <!-- LOADER -->
  <ng-container *ngIf="isLoading && order !== 'icon-last'">
    <mat-spinner class="ml-3" [diameter]="20"></mat-spinner>
  </ng-container>

  <!-- SUCCESSFUL -->
  <ng-container *ngIf="isSuccessful && !isLoading && order !== 'icon-last'">
    <mat-icon [ngClass]="[sizeClass, 'large']" [svgIcon]="'check'"></mat-icon>
  </ng-container>

</button>