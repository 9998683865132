<div class="form-fields-select-container mb-3">

  <!-- LABEL -->
  <div class="form-fields-select-container__label text-base text-gray-700 mb-1"
    *ngIf="(!IsNullOrUndefinedOrEmpty(label) || !IsNullOrUndefinedOrEmpty(displayName)) && displayLabel === true">
    {{ !IsNullOrUndefinedOrEmpty(label) ? label : displayName }} {{ required === true ? '*' : '' }}
  </div> <!-- /form-fields-select-container__label -->

  <!-- CONTENT -->
  <div class="form-fields-select-container__content">
    <mat-form-field appearance="outline" class="w-full">

      <!-- DEFAULT ROW TEMPLATE -->
      <ng-template #defaultRowTemplate let-item>
        {{ getLabel(item) }}
      </ng-template>

      <!-- DEFAULT TRIGGER TEMPLATE -->
      <ng-template #defaultTriggerTemplate let-item>
        {{ getLabel(item) }}
      </ng-template>

      <mat-select [formControl]="getAsFormControl(control)" [required]="required" [compareWith]="compareWith"
        [placeholder]="placeholder" class="camp-admin-form-field" [multiple]="multiple" >

        <!-- TRIGGER -->
        <mat-select-trigger>

          <!-- CUSTOM TRIGGER TEMPLATE -->
          <ng-container *ngIf="!IsNullOrUndefined(trigger?.template); else showDefaultTemplate">
            <ng-container
              *ngTemplateOutlet="trigger.template; context: { $implicit: getItemOrItemsByValue(control.value) }">
            </ng-container>
          </ng-container>

          <!-- DEFAULT TRIGGER TEMPLATE -->
          <ng-template #showDefaultTemplate>
            <ng-container
              *ngTemplateOutlet="defaultTriggerTemplate; context: { $implicit: getItemOrItemsByValue(control.value) }">
            </ng-container>
          </ng-template>
        </mat-select-trigger>

        <!-- OPTIONS -->
        <mat-option *ngFor="let item of items" [value]="getValue(item)">

          <!-- CUSTOM ROW TEMPLATE -->
          <ng-container *ngIf="!IsNullOrUndefined(row?.template); else showDefaultTemplate">
            <ng-container *ngTemplateOutlet="row.template; context: { $implicit: item }"></ng-container>
          </ng-container>

          <!-- DEFAULT ROW TEMPLATE -->
          <ng-template #showDefaultTemplate>
            <ng-container *ngTemplateOutlet="defaultRowTemplate; context: { $implicit: item }"></ng-container>
          </ng-template>

        </mat-option>
      </mat-select>

      <!-- ERRORS: REQUIRED -->
      <mat-error *ngIf="control?.hasError('required')">
        {{ "Cms.Required" | translate: { displayName: IsNullOrUndefinedOrEmpty(displayName) ? '&lt;please provide
        displayName&gt;' : displayName } }}
      </mat-error>

      <!-- ERRORS: DUPLICATED -->
      <mat-error *ngIf="control?.hasError('duplicated')">
        {{ "Cms.Duplicated" | translate } }
      </mat-error>

    </mat-form-field>
  </div> <!-- /form-fields-select-container__content -->
</div> <!-- /form-fields-select-container -->