import { Component, ContentChild, ContentChildren, Input, OnInit, QueryList } from '@angular/core';
import { BaseMenuButton } from '../shared/base-button';

import { ButtonsMenuActionComponent } from './action/buttons-menu-action.component';
import { ButtonsMenuLabelDirective } from './menu-label/buttons-menu-label.directive';

@Component({
  selector: 'intello-buttons-menu',
  templateUrl: './buttons-menu.component.html',
  styleUrls: ['./buttons-menu.component.scss']
})
export class ButtonsMenuComponent extends BaseMenuButton implements OnInit {

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  isMenuOpened: boolean = false;

  @Input() disabled: boolean = false;

  @Input() closesOnClick: boolean = true;

  // -----------------------------------------------------------------------------------------------------
  // @ CONTENT CHILDREN VARIABLES
  // -----------------------------------------------------------------------------------------------------

  private _label: ButtonsMenuLabelDirective;
  @ContentChild(ButtonsMenuLabelDirective, { static: true })
  get label(): ButtonsMenuLabelDirective {
    return this._label;
  }
  set label(value: ButtonsMenuLabelDirective) {
    this._label = value;
  }

  private _actions: QueryList<ButtonsMenuActionComponent>;

  @ContentChildren(ButtonsMenuActionComponent, { descendants: true })
  get actions(): QueryList<ButtonsMenuActionComponent> {
    return this._actions;
  }
  set actions(actions: QueryList<ButtonsMenuActionComponent>) {
    this._actions = actions;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor() {
    super();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  emitAction(event: any, action: ButtonsMenuActionComponent): void {
    if (this.closesOnClick) {
      action.onClick.emit();
    } else {
      event.stopPropagation();
      action.onClick.emit();
    }
  }
}
