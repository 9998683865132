import { Component, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BaseActionButton, BaseRouterButton } from '../../shared/base-button';

@Component({
  selector: 'intello-buttons-menu-action',
  templateUrl: './buttons-menu-action.component.html',
  styleUrls: ['./buttons-menu-action.component.scss']
})
export class ButtonsMenuActionComponent extends BaseActionButton implements OnInit {

  // -----------------------------------------------------------------------------------------------------
  // @ VIEW CHILD & VIEW CHILDREN VARIABLES
  // -----------------------------------------------------------------------------------------------------

  @ViewChild(TemplateRef, { static: true }) content: TemplateRef<any>;

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor() {
    super();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
  }

}
