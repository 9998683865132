import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[kbButtonsMenuLabel]'
})
export class ButtonsMenuLabelDirective {
  
  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(public template: TemplateRef<any>) { }
}
