import { ChangeDetectorRef, Directive, HostBinding, Input, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { BaseComponent } from '../../../base/components/base-component';
import { __ } from '../../../functions/object.functions';
import { Guid } from '../../../functions/guid';

@Directive()
export abstract class BaseFormFieldComponent extends BaseComponent implements OnInit {

    isComponentInitialized: boolean = false;

    @HostBinding('style.display') display: string = 'block';

    @Input() suffix: string;

    @Input() customRequiredMessage: string = '';

    @Input() hasIconSuffix: boolean = false;

    @Input() suffixPosition: 'start' | 'end' = 'end';

    //
    private _formFieldClass: string = '';

    @Input()
    public get formFieldClass(): string {
        return this._formFieldClass;
    }
    public set formFieldClass(value: string) {
        if (value !== this._formFieldClass) {
            this._formFieldClass = value;

            // TODO: HOW to do this better? Necessary so that it is not called before ngOnInit
            // see Error: ASSERTION ERROR: Should be run in update mode [Expected=> false == true <=Actual]
            if (this.isComponentInitialized) {
                this.cdr.detectChanges();
            } else {
                this.isComponentInitialized = true;
            }
        }
    }

    //
    private _id = Guid.NewGuid();

    @Input()
    get id() {
        return this._id;
    }
    set id(value: any) {
        this._id = value;
    }

    //
    private _displayLabel: boolean = true;

    @Input()
    get displayLabel(): boolean {
        return this._displayLabel;
    }
    set displayLabel(value: boolean) {
        this._displayLabel = value;
    }

    //
    private _control: AbstractControl;

    @Input()
    get control(): AbstractControl {
        return this._control;
    }
    set control(value: AbstractControl) {
        this._control = value;
    }

    //
    private _showLabel: boolean = true;

    @Input()
    get showLabel(): boolean {
        return this._showLabel;
    }
    set showLabel(value: boolean) {
        this._showLabel = value;
    }

    //
    private _required: boolean;

    @Input()
    get required(): boolean {
        return this._required;
    }
    set required(value: boolean) {
        this._required = value;
    }

    //
    private _disabled: boolean = false;

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = value;

        if (!__.IsNullOrUndefined(value) && !__.IsNullOrUndefined(this.control)) {
            if (value === true) {
                this.control.disable();
            }
            if (value === false) {
                this.control.enable();
            }
        } else {
            this.control.enable();
        }
    }

    //
    private _placeholder: string;

    @Input()
    get placeholder(): string {
        return this._placeholder;
    }
    set placeholder(value: string) {
        this._placeholder = value;
    }

    //
    private _displayName: string;

    @Input()
    get displayName(): string {
        return this._displayName;
    }
    set displayName(value: string) {
        this._displayName = value;
    }

    //
    private _name: string;

    @Input()
    get name(): string {
        return this._name;
    }
    set name(value: string) {
        this._name = value;
    }

    //
    private _label: string;

    @Input()
    get label(): string {
        return this._label;
    }
    set label(value: string) {
        this._label = value;
    }

    constructor(protected cdr: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        // error detection
        super.addSubscription(
            this.control.statusChanges.subscribe({
                next: (value: any) => {
                    this.cdr.detectChanges();
                }
            })
        );
    }

    getAsFormControl(control: AbstractControl): UntypedFormControl {
        return control as UntypedFormControl;
    }
}

@Directive()
export abstract class BaseTextFormFieldComponent extends BaseFormFieldComponent {

    // -----------------------------------------------------------------------------------------------------
    // @ INPUT VARIABLES
    // -----------------------------------------------------------------------------------------------------

    @Input() patternErrorMessage: string;

    @Input() pattern: RegExp;

    @Input() testId: string;

    //
    private _maxLength: number;

    @Input()
    get maxLength(): number {
        return this._maxLength;
    }
    set maxLength(value: number) {
        this._maxLength = value;
    }

    //
    private _minLength: number;

    @Input()
    get minLength(): number {
        return this._minLength;
    }
    set minLength(value: number) {
        this._minLength = value;
    }

    //
    private _max: number;

    @Input()
    get max(): number {
        return this._max;
    }
    set max(value: number) {
        this._max = value;
    }

    //
    private _min: number;

    @Input()
    get min(): number {
        return this._min;
    }
    set min(value: number) {
        this._min = value;
    }

    //
    private _lengthHint: boolean = true;

    @Input()
    get lengthHint(): boolean {
        return this._lengthHint;
    }
    set lengthHint(value: boolean) {
        this._lengthHint = value;
    }
}

@Directive()
export abstract class BaseDateFormFieldComponent extends BaseTextFormFieldComponent {

    @Input() maxDate: Date;

    @Input() minDate: Date;

}

@Directive()
export abstract class BaseSelectFormFieldComponent<T> extends BaseFormFieldComponent {

    @Input() items: T[] = [];

    @Input() multiple: boolean = false;

    @Input() compareWith: (a: T, b: T) => boolean = (a: T, b: T): boolean => {
        if (!__.IsNullOrUndefined(a['id']) && !__.IsNullOrUndefined(b['id'])) {
            return a['id'] === b['id'];
        }
        return a === b;
    }

    getLabel(item: T | T[]): string {
        if (item instanceof Array) {
            return (item as any[]).map(q => q?.displayName).toString();
        }
        return (item as any)?.displayName;
    }

    getValue(item: T | T[]): any {
        if (item instanceof Array) {
            return (item as any[]).map(q => q?.value).toString();
        }

        return (item as any)?.value;
    }

    getItemOrItemsByValue(value: any | any[]): T | T[] {
        if (value instanceof Array) {
            return this.items.filter(q => !__.IsNullOrUndefinedOrEmpty((value as any[]).find(p => this.getValue(q) === p)));
        }
        return this.items.find(q => this.getValue(q) === value);
    }
}

@Directive()
export abstract class BaseCheckboxFormFieldComponet<T> extends BaseFormFieldComponent {

}
